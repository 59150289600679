<template>
  <b-container fluid>
    <bo-page-title />
    <section id="career">
      <b-tabs v-if="isList">
        <b-tab title="Page Settings">
          <bo-card title="Hero Image">
            <b-row>
              <b-col md="4">
                <bo-card-img title="Karir" :src="uploader(heroImage.ssf_file, '/assets/images/no-data.jpg')">
                  <template #buttons>
                    <b-button
                      v-if="moduleRole('updateHero')"
                      variant="secondary"
                      pill
                      class="btn-icon"
                      v-b-tooltip.hover="'Update'"
                      @click="changeHero"
                    >
                      <i class="fas fa-pencil-alt" />
                    </b-button>
                  </template>
                </bo-card-img>
              </b-col>
            </b-row>
            <b-modal 
              v-model="heroEditorOpen"
              :title="'Update Hero Image '+ pageTitle"
              size="lg"
              hide-footer
              body-class="p-0"
            >
              <validation-observer
                v-slot="{ handleSubmit }" ref="VFormHero"
              >
                <b-form @submit.prevent="handleSubmit(submitHero)">
                  <b-card-body>
                    <b-row>
                      <b-col md="6">
                        <div class="file-uploader">
                          <label>Hero Image Desktop: </label>
                          <Uploader v-model="heroImage.ssf_file" type="hero-product"/>
                          <VValidate 
                            name="Hero Image Desktop" 
                            v-model="heroImage.ssf_file"
                            :rules="validationHeroImage.ssf_file" 
                          />
                        </div>
                        
                        <div class="file-uploader">
                          <label>Hero Image Mobile: </label>
                          <Uploader v-model="heroImage.ssf_file_mobile" type="hero-product-mobile" use-preview />
                          <VValidate 
                            name="Hero Image Mobile" 
                            v-model="heroImage.ssf_file_mobile"
                            :rules="validationHeroImage.ssf_file_mobile" 
                          />
                        </div>
                      </b-col>
                      <b-col md="6">
                        <b-card class="border mb-0">
                          <template #header>
                            <h5 class="card-title">Content (ID)</h5>
                          </template>
                          <b-form-group label-for="heroTitleId">
                            <template #label>Title <strong class="text-danger">*</strong></template>
                            <b-form-input id="heroTitleId" @keyup="removeWildChar" @keydown="removeWildChar" v-model="heroImage.ssf_title_id" @input="autoFill('ssf_alt_mobile_id','ssf_alt_id','ssf_title_id')"/>
                            <VValidate 
                              name="Content ID" 
                              v-model="heroImage.ssf_title_id"
                              :rules="{...validationHeroImage.ssf_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>

                          <b-form-group label-for="altId">
                            <template #label>Alt Image <strong class="text-danger">*</strong></template>
                            <b-form-input id="altId" @keyup="removeWildChar" @keydown="removeWildChar" v-model="heroImage.ssf_alt_id" />
                            <VValidate 
                              name="Alt Image Id" 
                              v-model="heroImage.ssf_alt_id"
                              :rules="{...validationHeroImage.ssf_alt_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                          
                          <b-form-group label-for="altIdM">
                          <template #label>Alt Image Mobile<strong class="text-danger">*</strong></template>
                            <b-form-input id="altIdM" @keyup="removeWildChar" @keydown="removeWildChar" v-model="heroImage.ssf_alt_mobile_id" />
                            <VValidate 
                            name="Alt Image Mobile ID" 
                            v-model="heroImage.ssf_alt_mobile_id"
                            :rules="{required:1, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                          />
                          </b-form-group>
                          
                        </b-card>
                        <b-card class="border mb-0">
                          <template #header>
                            <h5 class="card-title">Content (EN)</h5>
                          </template>
                          <b-form-group label-for="heroTitleEn">
                            <template #label>Title <strong class="text-danger">*</strong></template>
                            <b-form-input id="heroTitleEn" @keyup="removeWildChar" @keydown="removeWildChar" @input="autoFill('ssf_alt_mobile_en','ssf_alt_en','ssf_title_en')" v-model="heroImage.ssf_title_en"/>
                            <VValidate 
                              name="Content EN" 
                              v-model="heroImage.ssf_title_en"
                              :rules="{...validationHeroImage.ssf_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>

                          <b-form-group label-for="altEN">                          
                            <template #label>Alt Image <strong class="text-danger">*</strong></template>
                            <b-form-input id="altEN" @keyup="removeWildChar" @keydown="removeWildChar" v-model="heroImage.ssf_alt_en" />
                            <VValidate 
                              name="Alt Image En" 
                              v-model="heroImage.ssf_alt_en"
                              :rules="{...validationHeroImage.ssf_alt_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                          
                          <b-form-group label-for="altEnM">
                            <template #label>Alt Image Mobile<strong class="text-danger">*</strong></template>
                            <b-form-input id="altIEn" @keyup="removeWildChar" @keydown="removeWildChar" v-model="heroImage.ssf_alt_mobile_en" />
                            <VValidate 
                            name="Alt Image Mobile EN" 
                            v-model="heroImage.ssf_alt_mobile_en"
                            :rules="{required:1, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-card-body>
                  <b-card-footer>
                    <div class="text-right">
                      <b-button type="submit" variant="info" class="btn-rounded">Save Changes</b-button>
                    </div>
                  </b-card-footer>
                </b-form>
              </validation-observer>
            </b-modal>

          </bo-card>
          <b-card no-body v-if="moduleRole('updateCompany')">
            <template #header>
              <h5 class="card-title">Company Description</h5>
            </template>
            
            <validation-observer
              v-slot="{ handleSubmit }" ref="VFormContent"
            >
              <b-form @submit.prevent="handleSubmit(submitCompanyDesc)">
              <b-card-body>
                <b-row>
                  <b-col md="6">
                    <b-form-group label-for="contentDesc">
                      <template #label>Company Description (ID)<strong class="text-danger">*</strong></template>
                      <CKEditor 
                        id="CompanyDesc_id"
                        :value.sync="companyDesc.ssc_desc_id"
                        :customToolbar="itemsToolbar"
                      />
                      <VValidate 
                        name="Description ID" 
                        v-model="companyDesc.ssc_desc_id"
                        :rules="companyDescValidation.ssc_desc_id" 
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-for="contentDesc">
                      <template #label>Company Description (EN)<strong class="text-danger">*</strong></template>
                      <CKEditor 
                        id="CompanyDesc_en"
                        :value.sync="companyDesc.ssc_desc_en"
                        :customToolbar="itemsToolbar"
                      />
                      <VValidate 
                        name="Description EN" 
                        v-model="companyDesc.ssc_desc_en"
                        :rules="companyDescValidation.ssc_desc_en" 
                      />

                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-card-footer>
                <div class="text-right">
                  <b-button variant="info" type="submit" class="btn-rounded">Save Changes</b-button>
                </div>
              </b-card-footer>
            </b-form>
            </validation-observer>
          </b-card>
          <bo-card title="Job Vacancies">
            <b-row class="gutter-2" v-if="(data.data||[]).length">
              <b-col md="4" v-for="(career, key) in (data.data||[])" :key="key"
              >
                <div class="main-card main-card--sm career-card">
                  <div class="main-card-header">
                    <h3 class="career-card__title">{{career.mc_position}}</h3>
                  </div>
                  <div class="main-card-body">
                    <div class="career-reqs">
                      <b-row class="gutter-1">
                        <b-col md="12">
                          <b-badge :variant="career.mc_is_active == 'N' ? 'danger' : 'success'">
                          {{career.mc_is_active == 'Y' ? 'Active' : 'Inactive'}}
                          </b-badge>
                        </b-col>

                        <b-col md="6">
                          <div class="career-req__item">
                            <i class="icon-map"></i>
                            <span>{{career.mo_city + " - " + career.mof_name}}</span>
                          </div>
                        </b-col>
                        <b-col md="6" class="text-center">
                          <div class="career-req__item">
                            <i class="icon-clock"></i>
                            <span>{{career.mcj_name}}</span>
                          </div>
                        </b-col>
                        <b-col md="12">
                          <div class="career-req__item">
                            <span>Rp. {{career.mc_min_salary + " - " + career.mc_max_salary}}</span>
                          </div>
                        </b-col>
                        <b-col md="12">
                          <div class="career-req__item">
                            <i class="icon-briefcase"></i>
                            <span>{{career.mc_working_experience_id}}</span>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                  <div class="main-card-footer">
                    <div class="d-flex">
                      <router-link :to="{name: 'BoApplicants', query: {pos : String(career.mc_id)}}">
                        <b-button size="sm" variant="outline-info">See Applicants<i class="fas fa-arrow-circle-right ml-1"></i></b-button>
                      </router-link>
                      <div class="ml-auto">
                        <b-button
                          class="btn-icon"
                          variant="outline-warning"
                          v-if="moduleRole('edit')"
                          :to="{ name: routeName, params: { pageSlug: career.mc_id } }"
                          v-b-tooltip.hover="'Update Vacancy'"
                        >
                          <i class="fas fa-pencil-alt"></i>
                        </b-button>
                        
                        <b-button
                          class="btn-icon ml-1"
                          variant="outline-info"
                          @click="doChangeStatus(key,career)"
                          v-if="moduleRole('status')"
                          v-b-tooltip.hover="'Update Vacancy Status'"
                        >
                          <i class="fas fa-cog"></i>
                        </b-button>

                        <b-button
                          class="btn-icon ml-1"
                          variant="outline-danger"
                          @click="doDelete(key,career)"
                          v-if="moduleRole('delete')"
                          v-b-tooltip.hover="'Delete Vacancy'"
                        >
                          <i class="fas fa-trash-alt"></i>
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row v-else>
              <div class="text-center m-auto">
                <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
                <h4 align="center">There is no data</h4>
              </div>
            </b-row>
            <template #footer>
              <b-pagination
                  v-if="data.total > data.per_page"
									class="mb-0 justify-content-end"
									v-model="pageNo"
									:per-page="data.per_page"
									:total-rows="data.total"
								/>
            </template>
          </bo-card>
        </b-tab>
        
        <b-tab title="SEO Settings" v-if="moduleRole('updateSEO')">
          <b-card no-body>
            <b-card-header>
              <b-card-title title-tag="h5">Career SEO Settings</b-card-title>
            </b-card-header>
            <validation-observer
              v-slot="{ handleSubmit }" ref="VFormSEO"
            >
            <b-form @submit.prevent="handleSubmit(submitSEO)">
              <b-card-body>
                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTitle">
                      <template #label>
                        Meta Title (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="seoPageSetting.sss_meta_title_id"/>
                      <VValidate 
                        name="Meta Title ID" 
                        v-model="seoPageSetting.sss_meta_title_id"
                        :rules="{...seoValidation.sss_meta_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />   
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTitle">
                      <template #label>
                        Meta Title (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="seoPageSetting.sss_meta_title_en"/>
                      <VValidate 
                        name="Meta Title EN" 
                        v-model="seoPageSetting.sss_meta_title_en"
                        :rules="{...seoValidation.sss_meta_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />   
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaDesc">
                      <template #label>
                        Meta Description (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description" v-model="seoPageSetting.sss_meta_description_id"/>
                      <VValidate 
                        name="Meta Description ID" 
                        v-model="seoPageSetting.sss_meta_description_id"
                        :rules="{...seoValidation.sss_meta_description_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      /> 
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaDesc">
                      <template #label>
                        Meta Description (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field acts as the summary for your website\'s page. A good practice is to keep it around 120-155 characters.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description" v-model="seoPageSetting.sss_meta_description_en"/>
                      <VValidate 
                        name="Meta Description EN" 
                        v-model="seoPageSetting.sss_meta_description_en"
                        :rules="{...seoValidation.sss_meta_description_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      /> 
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTags">
                      <template #label>
                        Meta Keywords (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-tags id="homeMetaTags" v-model="seoPageSetting.sss_meta_keyword_id" placeholder="Type and press enter ..." remove-on-delete :tag-validator="validatorTags"
                        tag-variant="primary" tag-class="text-white"/>
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTags">
                      <template #label>
                        Meta Keywords (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field tell Search Engines what the topic of a page is. Use a relevant and short topic, and make sure to keep only up to 10 keywords.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-tags id="homeMetaTags" v-model="seoPageSetting.sss_meta_keyword_en" placeholder="Type and press enter ..." remove-on-delete :tag-validator="validatorTags"
                        tag-variant="primary" tag-class="text-white"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-card-footer>
                <div class="text-right">
                  <b-button class="btn-rounded" type="submit" variant="primary">Save Changes</b-button>
                </div>
              </b-card-footer>
            </b-form>
            </validation-observer>
          </b-card>
        </b-tab>
      </b-tabs>
      <template v-else>
      
        <validation-observer
          ref="VForm"
        >
        <b-form @submit.prevent="submitCareer">
          <b-tabs v-model="tabIndex">
            <b-tab title="Job Vacancy Details" active>
              <b-card>
                <template #header>
                  <h5 class="card-title">Job Vacancy Information</h5>
                </template>
                <b-row>
                  <b-col md="9">
                    <b-row>
                      <b-col md="4">
                        <b-form-group label-for="jobPosition">
                          <template #label>Position<strong class="text-danger">*</strong></template>
                          <b-form-input id="jobPosition" v-model="row.mc_position" @keyup="removeWildChar" @keydown="removeWildChar"/>
                          <VValidate 
                            name="Position" 
                            v-model="row.mc_position" 
                            :rules="{...mrValidation.mc_position, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label-for="jobExperienceid">
                          <template #label>Min. Working Experience (ID)<strong class="text-danger">*</strong></template>
                          <b-form-input id="jobExperienceid" v-model="row.mc_working_experience_id" @keyup="removeWildChar" @keydown="removeWildChar" />                      
                          <VValidate 
                            name="Job Experience ID" 
                            v-model="row.mc_working_experience_id" 
                            :rules="{...mrValidation.mc_working_experience_id,regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label-for="jobExperienceen">
                          <template #label>Min. Working Experience (EN)<strong class="text-danger">*</strong></template>
                          <b-form-input id="jobExperienceen" v-model="row.mc_working_experience_en" @keyup="removeWildChar" @keydown="removeWildChar" />                      
                          <VValidate 
                            name="Job Experience EN" 
                            v-model="row.mc_working_experience_en" 
                            :rules="{...mrValidation.mc_working_experience_en,regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label-for="jobType">
                          <template #label>Job Type<strong class="text-danger">*</strong></template>
                          <v-select id="jobType"  :reduce="el => el.value" v-model="row.mc_job_type" :options="workJob" placeholder="e.g. Full-time" />
                          <VValidate 
                            name="Job Type" 
                            v-model="row.mc_job_type" 
                            :rules="mrValidation.mc_job_type" 
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label-for="jobLocation">
                          <template #label>Working Location ID<strong class="text-danger">*</strong></template>
                          <v-select id="jobLocation" :reduce="el => el.value" v-model="row.mc_working_location" :options="workRegion" placeholder="Select Working Location" />
                          <VValidate 
                            name="Working Location" 
                            v-model="row.mc_working_location" 
                            :rules="mrValidation.mc_working_location" 
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="8">
                        <b-form-group label-for="salaryRange">
                          <template #label>Salary Range <strong class="text-danger">*</strong></template>
                          <b-input-group>
                            <b-form-input id="minSalary" v-model="row.mc_min_salary" :formatter="value => parseInt((value || '0').replace(/[.,]/g, '')).toLocaleString('id-ID')"/>
                            <div class="input-group-prepend input-group-append">
                              <b-input-group-text>-</b-input-group-text>
                            </div>
                            <b-form-input id="maxSalary" v-model="row.mc_max_salary"  :formatter="value => parseInt((value || '0').replace(/[.,]/g, '')).toLocaleString('id-ID')"/>
                          </b-input-group>
                          <VValidate 
                            name="Minimal Salary " 
                            v-model="row.mc_min_salary" 
                            :rules="{required:1, regex:/^[0-9\.]+$/}" 
                          />

                          <VValidate 
                            name="Maximal Salary" 
                            v-model="max_salary" 
                            :rules="{required:1, regex:/^[0-9\.]+$/, min_value: min_salary }" 
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label-for="jobDescriptionId">
                          <template #label>Job Description (ID)<strong class="text-danger">*</strong></template>
                          <CKEditor 
                            id="jobDescriptionId"
                            :value.sync="row.mc_desc_id"
                            :customToolbar="itemsToolbar"
                          />
                          <VValidate 
                            name="Description Id" 
                            v-model="row.mc_desc_id" 
                            :rules="mrValidation.mc_desc_id" 
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label-for="jobDescriptionEn">
                          <template #label>Job Description (EN)<strong class="text-danger">*</strong></template>
                          <CKEditor 
                            id="jobDescriptionEn"
                            :value.sync="row.mc_desc_en"
                            :customToolbar="itemsToolbar"
                          />
                          <VValidate 
                            name="Description En" 
                            v-model="row.mc_desc_en" 
                            :rules="mrValidation.mc_desc_en" 
                          />
                        
                        </b-form-group>
                      </b-col>
                      <b-col md="8">
                        <b-form-group label-for="jobSkills">
                          <template #label>Required Skills <strong class="text-danger">*</strong></template>
                          <b-form-tags v-model="row.mc_required_skills" placeholder="Type and press enter ..." remove-on-delete
                            tag-variant="primary" tag-class="text-white"/>
                            <VValidate 
                              name="Required Skills" 
                              v-model="row.mc_required_skills" 
                              :rules="mrValidation.mc_required_skills" 
                            />
                          </b-form-group>

                      </b-col>
                      <b-col md="8">
                        <b-form-group label-for="jobAdditionalSkills">
                          <template #label>Additional Skills</template>
                          <b-form-tags v-model="row.mc_additional_skills" placeholder="Type and press enter ..." remove-on-delete
                            tag-variant="primary" tag-class="text-white"/>
                        </b-form-group>
                      </b-col>
                      <b-col md="10">
                        <b-card class="border">
                          <template #header>
                            <h5 class="card-title">Job Benefits</h5>
                          </template>
                          <b-form-row v-for="(benefit, key) in row.mc_benefits" :key="key">
                            <template v-if="benefit['benefits_id'+(key+1)] !== null">
                              <b-col md>
                                <b-form-group class="mb-2" :label-for="'jobBenefitId' +  key">
                                  <template #label>Benefit (ID)<strong class="text-danger">*</strong></template>
                                  <b-form-input :id="'jobBenefitId' +  key" v-model="benefit['benefits_id'+(key+1)]" @keyup="removeWildChar" @keydown="removeWildChar" />
                                </b-form-group>
                              </b-col>
                              <b-col md>
                                <b-form-group class="mb-2" :label-for="'jobBenefitEn' + key" >
                                  <template #label>Benefit (EN) <strong class="text-danger">*</strong></template>
                                  <b-form-input :id="'jobBenefitEn' + key"  v-model="benefit['benefits_en'+(key+1)]" 
                                  @keyup="removeWildChar" @keydown="removeWildChar" />
                                </b-form-group>
                              </b-col>
                              <b-col md="auto">
                                <div class="form-group">
                                  <label for="" class="d-block">&nbsp;</label>
                                  <b-button class="btn-icon" @click="addBenefits()" variant="outline-success">
                                    <i class="fas fa-plus"></i>
                                  </b-button>
                                  <b-button class="btn-icon ml-1" v-if="lengthBenefits > 1" @click="deleteBenefits(key)" variant="outline-danger">
                                    <i class="fas fa-trash-alt"></i>
                                  </b-button>
                                </div>
                              </b-col>
                            </template>
                          </b-form-row>
                          <small id="Benefits" 
                            v-if="ValidateBenefit" 
                            class="label_error" style="">The Benefits field is required</small>
                          
                        </b-card>
                      </b-col>
                    </b-row>
                    <b-form-group>                   
                    <label class="control-label">Status <span class="text-danger mr5">*</span> </label>
                    <div class="row">
                      <div class="col-md-1">
                        <div class="custom-control custom-radio">
                          <input type="radio" id="customRadio1" v-model="row.mc_is_active" value="Y"
                            name="radioRow" class="custom-control-input">
                          <label class="custom-control-label" for="customRadio1">Active</label>
                        </div>
                      </div>
                      <div class="col-md-1">
                        <div class="custom-control custom-radio">
                          <input type="radio" id="customRadio3" v-model="row.mc_is_active" value="N"
                            name="radioRow" class="custom-control-input">
                          <label class="custom-control-label" for="customRadio3">Inactive</label>
                        </div>
                      </div>
                      <VValidate name="Status" v-model="row.mc_is_active"
                        :rules="mrValidation.mc_is_active" />
                    </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
            <b-tab title="SEO Settings">
              <b-card no-body>
                <b-card-header>
                  <b-card-title title-tag="h5">Vacancy Details SEO Settings</b-card-title>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col lg="6">
                      <b-form-group label-for="homeMetaTitle">
                        <template #label>
                          Meta Title (ID)
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-input v-model="row.mc_meta_title_id" id="homeMetaTitle" placeholder="Meta Title"/>
                        <VValidate 
                          name="Meta Title Career ID" 
                          v-model="row.mc_meta_title_id" 
                          :rules="{...mrValidation.mc_meta_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                      </b-form-group>
                    </b-col>

                    <b-col lg="6">
                      <b-form-group label-for="homeMetaTitle">
                        <template #label>
                          Meta Title (EN)
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-input v-model="row.mc_meta_title_en" id="homeMetaTitle" placeholder="Meta Title"/>
                        <VValidate 
                          name="Meta Title Career EN" 
                          v-model="row.mc_meta_title_en" 
                          :rules="{...mrValidation.mc_meta_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col lg="6">
                      <b-form-group label-for="homeMetaDesc">
                        <template #label>
                          Meta Description (ID)
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-textarea rows="5" v-model="row.mc_meta_description_id" id="homeMetaDesc" placeholder="Meta Description"/>
                        <VValidate 
                          name="Meta Description Career ID" 
                          v-model="row.mc_meta_description_id" 
                          :rules="{...mrValidation.mc_meta_description_id,regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                      </b-form-group>
                    </b-col>

                    <b-col lg="6">
                      <b-form-group label-for="homeMetaDesc">
                        <template #label>
                          Meta Description (EN)
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'This field acts as the summary for your website\'s page. A good practice is to keep it around 120-155 characters.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-textarea rows="5" v-model="row.mc_meta_description_en" id="homeMetaDesc" placeholder="Meta Description"/>
                        <VValidate 
                          name="Meta Description Career EN" 
                          v-model="row.mc_meta_description_en" 
                          :rules="{...mrValidation.mc_meta_description_en,regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col lg="6">
                      <b-form-group label-for="homeMetaTags">
                        <template #label>
                          Meta Keywords (ID)
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-tags v-model="row.mc_meta_keyword_id" id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete :tag-validator="validatorTags"
                          tag-variant="primary" tag-class="text-white"/>
                      </b-form-group>
                    </b-col>

                    <b-col lg="6">
                      <b-form-group label-for="homeMetaTags">
                        <template #label>
                          Meta Keywords (EN)
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'This field tell Search Engines what the topic of a page is. Use a relevant and short topic, and make sure to keep only up to 10 keywords.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-tags v-model="row.mc_meta_keyword_en" id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete :tag-validator="validatorTags"
                          tag-variant="primary" tag-class="text-white"/>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-tab>
          </b-tabs>
          <b-card no-body>
            <template #footer>
              <div class="text-right">
                <b-button type="submit" variant="info" class="btn-rounded">Save Changes</b-button>
              </div>
            </template>
          </b-card>
        </b-form>
        </validation-observer>
      </template>
    </section>
  </b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import BoCardImg from '@/components/backend/BoCardImg.vue'
import CKEditor from '@/components/CKEditor'

let $ = global.jQuery
let _ = global._

export default {
  extends: GlobalVue,
  components: {
    BoCardImg,
    CKEditor
  },
  data() {
    return {
      seoRow:{},
      currentTitle: '',
      timer: null,
      heroEditorOpen: false,
      heroImage: {},
      validationHeroImage: {},
      seoPageSetting: {},
      seoValidation: {},
      data: [],
      companyDesc: {},
      companyDescValidation: {},
      idKey:'mc_id',    
      statusKey: 'mc_is_active',
      workExp: [],
      workJob: [],
      workRegion: [],
      mrValidation: {},
      tabIndex : 0,
      ValidateBenefit : false,
      
      itemsToolbar: [
        'bold',
        'italic',
      ],

      lengthBenefits : 0
    }
  },
  computed: {
    max_salary(){ return (this.row.mc_max_salary||'0').replace(/[.,]/g, '') },
    min_salary(){ return (this.row.mc_min_salary||'0').replace(/[.,]/g, '') }
  },
  methods: {
    changeHero(){
      this.heroEditorOpen = !this.heroEditorOpen
    },
    autoFill(to = "", to2 = "", from = ""){
      this.heroImage[to] = this.heroImage[from]
      this.heroImage[to2] = this.heroImage[from]    
    },
    submitCompanyDesc(IdFormContent = 'VFormContent'){
      this.doSubmit(
        "/do/" + this.modulePage,
        _.assign({
          type: 'updateCompanyDesc'
        }, this.companyDesc),
        (type, resp) => {
          if (type == 'success') {
            this.loadingOverlay = true  
            
            if(Object.keys(resp.data.rowAlgoliaID).length){
              this.submitStaicContentAlgolia('company-description',resp.data.rowAlgoliaID,'id')
            }
            if(Object.keys(resp.data.rowAlgoliaEN).length){
              this.submitStaicContentAlgolia('company-description',resp.data.rowAlgoliaEN,'en')
            }

            this.apiGet()
          }else{
            if(resp.response.status==422) {
              if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                var msg = ""
                $.each(Object.keys(resp.response.data), (i, v) => {
                  msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v])
                })
                return this.$swal.fire("Blocked With Validation!", msg)
              } else {
                return this.$swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
              }
            } else if (resp.response.status == 400) {
              return this.$swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
        },
        'POST', IdFormContent
      )
    },
    
    addBenefits(){
      let length = this.row.mc_benefits.length
      let data = {}
      data["benefits_id" + (length+ 1)] = ""
      data["benefits_en" + (length+ 1)] = ""
      this.row.mc_benefits.push(data)
      this.lengthBenefits = this.lengthBenefits + 1
    },

    deleteBenefits(key){
      if(this.row.mc_benefits.length > 1){
        this.row.mc_benefits[key]['benefits_id'+(key+1)] = null
        this.row.mc_benefits[key]['benefits_en'+(key+1)] = null
        this.lengthBenefits = this.lengthBenefits -1 
      }
    },

    submitCareer(){
      if(!this.row.mc_meta_title_id || !this.row.mc_meta_title_en ||!this.row.mc_meta_description_id || !this.row.mc_meta_description_en){
          this.tabIndex = 1
      }

      if(!this.row.mc_position || !this.row.mc_working_experience_id || !this.row.mc_working_experience_en || !this.row.mc_job_type || !this.row.mc_min_salary || !this.row.mc_max_salary || !this.row.mc_working_location ||
      !this.row.mc_desc_id || !this.row.mc_desc_en || !this.row.mc_required_skills){
          this.tabIndex = 0
      }
      
      for(let i = 0; i < this.row.mc_benefits.length; i++){
        if(this.row.mc_benefits[i]['benefits_id'+(i+1)] == "" || 
        this.row.mc_benefits[i]['benefits_en'+(i+1)] == ""){
          this.ValidateBenefit = true
          return
        }
      }

      this.ValidateBenefit = false
      
      this.$refs['VForm'].validate().then(success => {
        if (!success) return        
        var newArrBenefits = []
        for(let i = 0; i < this.row.mc_benefits.length; i++){
          if(this.row.mc_benefits[i]['benefits_id'+(i+1)]){
            newArrBenefits.push({
              benefits_id : this.row.mc_benefits[i]['benefits_id'+(i+1)],
              benefits_en : this.row.mc_benefits[i]['benefits_en'+(i+1)]    
            })
          }
        }

        let subArrBenefits = []
        for(let i = 0; i < newArrBenefits.length; i++){
            let data = {}
            data["benefits_id" + (i+ 1)] = newArrBenefits[i].benefits_id
            data["benefits_en" + (i+ 1)] = newArrBenefits[i].benefits_en
            subArrBenefits.push(data)
        }
        this.row.mc_benefits = subArrBenefits
        this.doSubmitCRUD('VForm', this.$refs)
      })
    },
    minSalary(){
      let value = parseFloat(this.row.mc_min_salary.replace(/,/g, '')).toLocaleString('id-ID', {
        style: 'decimal'
      })
      this.row.mc_min_salary = value
    },
    maxSalary(){
      let value = parseFloat(this.row.mc_max_salary).toLocaleString('id-ID', {
        style: 'decimal'
      })
      this.row.mc_max_salary = value
    }
  },
  mounted() {
    this.apiGet()    
  },
  watch:{
    $route(){
      this.apiGet()  
    },
    'row.mc_position'(newVal){

      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        this.row.mc_meta_title_id = newVal
        this.row.mc_meta_title_en = newVal
        
        if(!this.row.mc_meta_keyword_id) this.$set(this.row, 'mc_meta_keyword_id', [])
        if(!this.row.mc_meta_keyword_en) this.$set(this.row, 'mc_meta_keyword_en', [])

        const indexKeywordId = this.row.mc_meta_keyword_id.indexOf(this.currentTitle)
        if(indexKeywordId > -1){
          this.row.mc_meta_keyword_id.splice(indexKeywordId, 1, newVal)
        }else {
          this.row.mc_meta_keyword_id.push(newVal)
        }

        const indexKeywordEn = this.row.mc_meta_keyword_en.indexOf(this.currentTitle)
        if(indexKeywordEn > -1){
          this.row.mc_meta_keyword_en.splice(indexKeywordEn, 1, newVal)
        }else {
          this.row.mc_meta_keyword_en.push(newVal)
        }

        this.currentTitle = newVal
      }, 1500)


    },
        // counter seo
    'seoPageSetting.sss_meta_title_id'(v){
      let el = document.getElementById('Meta Title ID')
      if(el){
        let showcount = document.getElementById('Meta Title ID' + 'count')
        let cErr = document.getElementById('Meta Title ID' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          el.style.display = "none"
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"        
        }
      }
    },
    'seoPageSetting.sss_meta_title_en'(v){
      let el = document.getElementById('Meta Title EN')
      if(el){
        let showcount = document.getElementById('Meta Title EN' + 'count')
        let cErr = document.getElementById('Meta Title EN' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"          
          showcount.style.display = "none"
        }
      }
    },
    'seoPageSetting.sss_meta_description_id'(v){
      let el = document.getElementById('Meta Description ID')
      if(el){
        let showcount = document.getElementById('Meta Description ID' + 'count')
        let cErr = document.getElementById('Meta Description ID' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },
    'seoPageSetting.sss_meta_description_en'(v){
      let el = document.getElementById('Meta Description EN')
      if(el){
        let showcount = document.getElementById('Meta Description EN' + 'count')
        let cErr = document.getElementById('Meta Description EN' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },
    'row.mc_desc_id'(v){
      this.row.mc_meta_description_id = v.replace(/<[^>]*>?/gm, '')
    },
    'row.mc_desc_en'(v){
      this.row.mc_meta_description_en = v.replace(/<[^>]*>?/gm, '')
    },
    'row.mc_meta_title_id'(v){
      let el = document.getElementById('Meta Title Career ID')
      if(el){
        let showcount = document.getElementById('Meta Title Career ID' + 'count')
        let cErr = document.getElementById('Meta Title Career ID' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"        
        }
      }
    },
    'row.mc_meta_title_en'(v){
      let el = document.getElementById('Meta Title Career EN')
      if(el){
        let showcount = document.getElementById('Meta Title Career EN' + 'count')
        let cErr = document.getElementById('Meta Title Career EN' + 'count-err')        
        if(v.length >= 3 && v.length < 60){
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"         
          showcount.style.display = "none"        
        }
      }
    },
    'row.mc_meta_description_id'(v){
      let el = document.getElementById('Meta Description Career ID')
      if(el){
        let showcount = document.getElementById('Meta Description Career ID' + 'count')
        let cErr = document.getElementById('Meta Description Career ID' + 'count-err')        
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"                  
          showcount.style.display = "none"        
        }
      }
    },
    'row.mc_meta_description_en'(v){
      let el = document.getElementById('Meta Description Career EN')
      if(el){
        let showcount = document.getElementById('Meta Description Career EN' + 'count')
        let cErr = document.getElementById('Meta Description Career EN' + 'count-err')        
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"                  
          showcount.style.display = "none"        
        }
      }
    }
  }
}
</script>